import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesDatabaseDataQuery {
            servicesdatabasedataJson(jsonId: {eq: "database-content"}) {
                databaseServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                    buttonColor
                }
            }
        }    
    `)
    const serviceData = servicesQueryData.servicesdatabasedataJson;
    const databaseServices = serviceData.databaseServices;
    // console.log(serviceData.databaseServices)

    // const databaseContent = databaseServices.map((databaseService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={databaseService.link}>{databaseService.title}</a>
    //     </div>
    // );

    const content = databaseServices.map((databaseService, index) =>
        <div className="row services" key={index} id={databaseService.classId}>
            <SectionInnerLeft>
                <SectionTitleWrap style={{ paddingRight: 150 }}>
                    <Heading {...HeadingStyleServies}>{databaseService.title}</Heading>
                    {databaseService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                </SectionTitleWrap>
            </SectionInnerLeft>
            <SectionInnerRight style={{ marginTop: 80 }}>
                {databaseService.image ?
                    <Fade right>
                        <img src={databaseService.image.childImageSharp.fluid.src} alt={databaseService.title} />
                    </Fade>
                    :
                    <Fade right>
                        <SectionTitleWrap>
                            {databaseService.tableHeader && <Heading {...SubHeadingStyle}>{databaseService.tableHeader}</Heading>}
                            <div className="row">
                                {databaseService.tableData.map((textData, i) =>
                                    <div className="col-1" key={`story-text-${i}`}>
                                        <Text {...Paragraph}>{textData}</Text>
                                    </div>)
                                }
                            </div>
                            {databaseService.link && <Button style={{ background: databaseService.buttonColor && databaseService.buttonColor }}
                                className="button" as={Link} to={databaseService.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                            </Button>}
                        </SectionTitleWrap>
                    </Fade>
                }
            </SectionInnerRight>
        </div>
    );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>Database and Storage</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage style={{ marginTop: 10 }} src={"../../../../src/data/images/databases1.png"} alt="database header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="never-run-space" {...HeadingStyleServies}>Never run out of space</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <Text {...Paragraph}>
                            The data storage landscape continues to evolve to serve the needs of businesses across the globe. We’ve come a long way over the past decade, with advances ushering in capacity improvements in storage solutions such as hard drives and tape. Many businesses have also embraced highly-scalable, cloud storage solutions. Additionally, on-premises storage architectures have significantly improved to meet the growing needs of organizations.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ backgroundColor: "#4115B7", color: "white" }}
                        >
                            Learn More
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            {/*<div className="row services">*/}
            {/*    <div className="col-4 offset-1">*/}
            {/*        <SectionInner>*/}
            {/*            <SectionTitleWrap>*/}
            {/*                <Heading {...SubHeadingStyle}>DATABASE AND STORAGE SERVICES</Heading>*/}
            {/*            </SectionTitleWrap>*/}
            {/*        </SectionInner>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {content}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    databaseServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

