import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/database'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Database Engineering and Design" description="We provide database design and implementation services to suit every organisations needs and use cases."/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

